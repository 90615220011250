
import { mapState, mapGetters } from 'vuex';
import AssetListItem from "./AssetListItem.vue";
import AssetListItemForm from "./AssetListItemForm.vue";
import CommonSearchField from "../common/SearchField.vue";

export default {
  name: "AssetList",
  components: {
    AssetListItem,
    AssetListItemForm,
    CommonSearchField,
  },
  props: {
    widgetClasses: String,
  },
  data: () => ({
    isAddingActive: false,
    searchQuery: null,
  }),
  computed: {
    ...mapState('assets__new', [
      'getAssetsResult',
      'getAssetsIsLoading',
      'getAssetsError',
    ]),
    ...mapGetters('assets__new', [
      'getAssetsResultFiltered',
    ]),
    assets() {
      return this.getAssetsResultFiltered;
    },
  },
  methods: {
    toggleAdd() {
      this.isAddingActive = !this.isAddingActive;
    },
    cancelAdd() {
      this.isAddingActive = false;
    },
    setSearchQuery(query) {
      if (!query) {
        this.searchQuery = null;
      } else {
        this.searchQuery = query;
      }
    },
  },
  watch: {
    searchQuery() {
      this.$store.state.assets__new.searchQuery = this.searchQuery;
    },
  },
}
