
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { GoogleMap, Marker } from "vue3-google-map";

export default {
    name: "AssetMap",
    components: {
        GoogleMap,
        Marker,
    },
    props: {
        widgetClasses: String,
    },
    setup() {
        const store = useStore();
        const mapRef = ref(null);
        const center = { lat: 40.689247, lng: -74.044502 };
        const theme = [
            { elementType: "geometry", stylers: [{ color: "#1d2c4d" }] },
            {
                elementType: "labels.text.fill",
                stylers: [{ color: "#8ec3b9" }],
            },
            {
                elementType: "labels.text.stroke",
                stylers: [{ color: "#1a3646" }],
            },
            {
                featureType: "administrative.country",
                elementType: "geometry.stroke",
                stylers: [{ color: "#4b6878" }],
            },
            {
                featureType: "administrative.land_parcel",
                elementType: "labels.text.fill",
                stylers: [{ color: "#64779e" }],
            },
            {
                featureType: "administrative.province",
                elementType: "geometry.stroke",
                stylers: [{ color: "#4b6878" }],
            },
            {
                featureType: "landscape.man_made",
                elementType: "geometry.stroke",
                stylers: [{ color: "#334e87" }],
            },
            {
                featureType: "landscape.natural",
                elementType: "geometry",
                stylers: [{ color: "#023e58" }],
            },
            {
                featureType: "poi",
                elementType: "geometry",
                stylers: [{ color: "#283d6a" }],
            },
            {
                featureType: "poi",
                elementType: "labels.text.fill",
                stylers: [{ color: "#6f9ba5" }],
            },
            {
                featureType: "poi",
                elementType: "labels.text.stroke",
                stylers: [{ color: "#1d2c4d" }],
            },
            {
                featureType: "poi.park",
                elementType: "geometry.fill",
                stylers: [{ color: "#023e58" }],
            },
            {
                featureType: "poi.park",
                elementType: "labels.text.fill",
                stylers: [{ color: "#3C7680" }],
            },
            {
                featureType: "road",
                elementType: "geometry",
                stylers: [{ color: "#304a7d" }],
            },
            {
                featureType: "road",
                elementType: "labels.text.fill",
                stylers: [{ color: "#98a5be" }],
            },
            {
                featureType: "road",
                elementType: "labels.text.stroke",
                stylers: [{ color: "#1d2c4d" }],
            },
            {
                featureType: "road.highway",
                elementType: "geometry",
                stylers: [{ color: "#2c6675" }],
            },
            {
                featureType: "road.highway",
                elementType: "geometry.stroke",
                stylers: [{ color: "#255763" }],
            },
            {
                featureType: "road.highway",
                elementType: "labels.text.fill",
                stylers: [{ color: "#b0d5ce" }],
            },
            {
                featureType: "road.highway",
                elementType: "labels.text.stroke",
                stylers: [{ color: "#023e58" }],
            },
            {
                featureType: "transit",
                elementType: "labels.text.fill",
                stylers: [{ color: "#98a5be" }],
            },
            {
                featureType: "transit",
                elementType: "labels.text.stroke",
                stylers: [{ color: "#1d2c4d" }],
            },
            {
                featureType: "transit.line",
                elementType: "geometry.fill",
                stylers: [{ color: "#283d6a" }],
            },
            {
                featureType: "transit.station",
                elementType: "geometry",
                stylers: [{ color: "#3a4762" }],
            },
            {
                featureType: "water",
                elementType: "geometry",
                stylers: [{ color: "#0e1626" }],
            },
            {
                featureType: "water",
                elementType: "labels.text.fill",
                stylers: [{ color: "#4e6d70" }],
            },
        ];

        const getAssetsResultFiltered = computed(
            () => store.getters["assets__new/getAssetsResultFiltered"]
        );

        watch(
            [getAssetsResultFiltered, mapRef, () => mapRef?.value?.ready],
            ([getAssetsResultFiltered, mapRef, isMapReady]) => {
                if (!isMapReady || !getAssetsResultFiltered) {
                    return;
                }
                if (getAssetsResultFiltered?.length > 0) {
                    const map = mapRef.map;
                    const bounds = new mapRef.api.LatLngBounds();
                    for (let i = 0; i < getAssetsResultFiltered.length; i++) {
                        bounds.extend(getAssetsResultFiltered[i].geom);
                    }
                    map.fitBounds(bounds);
                    map.setCenter(bounds.getCenter());
                    if (map.getZoom() > 15) {
                        map.setZoom(15);
                    }
                }
            }
        );
        return {
            mapRef,
            getAssetsResultFiltered,
            center,
            theme,
        };
    },
};
