
import { mapState } from 'vuex';
import AssetList from "@/components/assets/AssetList"
import AssetMap from "@/components/assets/AssetMap"
import Loader from "@/components/Loader"

export default {
  name: "Assets",
  components: {
    AssetList,
    AssetMap,
    Loader,
  },
  computed: {
    ...mapState('assets__new', [
      'getAssetsResult',
      'getAssetsIsLoading',
      'getAssetsError',
    ]),
  },
}
